import React, { useContext, useEffect, useState } from 'react'
import TaskContext from '../context/TaskContext'
import ContentEditable from 'react-contenteditable'
import { ReactComponent as CloseIcon } from '../assets/svg/closeIcon.svg'

function DetailModal() {
  const {
    activeItemData,
    setActiveItemData,
    setOpenModal,
    completeTask,
    deleteTask,
    uncompleteTask,
    updateTask,
    uncommitTask,
    archiveTask,
  } = useContext(TaskContext)
  const [taskEdit, setTaskEdit] = useState(false)
  const [updFormData, setUpdFormData] = useState({
    ...activeItemData,
  })

  // Use useEffect to listen to the last input change so the last letter doesn't get cut off
  useEffect(() => {
    updateTask(updFormData, activeItemData.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updFormData])

  // Listener for when something in Title changes
  const onTitleChange = (e) => {
    setUpdFormData((prevState) => ({
      ...prevState,
      title: e.target.value,
      markedForSync: true,
    }))
  }

  // Listener for when something in Description changes
  const onDescChange = (e) => {
    setUpdFormData((prevState) => ({
      ...prevState,
      description: e.target.value,
      markedForSync: true,
    }))
  }

  // Update data in db & local storage when modal is closed
  const updateTaskData = () => {
    // Delete the task if there's no title
    if (updFormData.title === '') {
      // If the item is in Today then send the proper argument to the deleteTask function
      if (activeItemData.committed) {
        deleteTask(activeItemData.id, true)
      } else {
        deleteTask(activeItemData.id, false)
      }
    }

    // If anything has changed, send it to the DB
    if (
      activeItemData.title !== updFormData.title ||
      activeItemData.description !== updFormData.description ||
      updFormData.markedForSync
    ) {
      // Update details to firestore
      // updateTask(updFormData, activeItemData.id, activeItemData.column, true)
      updateTask(updFormData, activeItemData.id)
    }

    setOpenModal(false)
  }

  // Mark a task as complete
  const handleCompleteTask = () => {
    if (!activeItemData.archived) {
      completeTask(updFormData, activeItemData.id)
      setUpdFormData((prevState) => ({
        ...prevState,
        completed: true,
        committed: true,
        markedForSync: true,
      }))

      setActiveItemData((prevState) => ({
        ...prevState,
        completed: true,
        committed: true,
        column: 'today',
        markedForSync: true,
      }))
    }
  }

  // Unmark a task as complete
  const handleUncompleteTask = () => {
    if (!activeItemData.archived) {
      uncompleteTask(updFormData, activeItemData.id, activeItemData.column)

      setUpdFormData((prevState) => ({
        ...prevState,
        completed: false,
        markedForSync: true,
      }))

      setActiveItemData((prevState) => ({
        ...prevState,
        completed: false,
        markedForSync: true,
      }))
    }
  }

  // Handler to delete a task
  const handleDeleteTask = () => {
    if (activeItemData.committed) {
      deleteTask(activeItemData.id, true)
    } else {
      deleteTask(activeItemData.id, false)
    }

    setOpenModal(false)
  }

  const handleArchiveTask = () => {
    archiveTask(updFormData)
    setOpenModal(false)
  }

  const handleUncommitTask = () => {
    if (activeItemData.committed) {
      uncommitTask(activeItemData.id)
    }
    setOpenModal(false)
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center min-h-screen md:pt-4 md:px-4 md:pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
          onClick={updateTaskData}
        ></div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        >
          &#8203;
        </span>
        <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full h-screen lg:w-[50%] lg:h-[90vh] sm:px-6 sm:pt-3 sm:pb-6'>
          <div className='nav pb-5 flex justify-between'>
            <div className='grid content-center text-xs text-stone-400'>
              <div>
                Status:{' '}
                <span className='font-medium'>
                  {activeItemData.archived
                    ? 'Archived'
                    : activeItemData.committed
                    ? 'Committed'
                    : 'Inbox'}
                </span>
              </div>
            </div>
            <div className='flex'>
              <div className='px-4 hidden sm:block'>
                {activeItemData.committed && !activeItemData.archived ? (
                  <button
                    onClick={handleUncommitTask}
                    className='h-6 px-2 border border-stone-400 rounded-[5px] text-xs font-medium text-stone-400 transition-colors duration-150 focus:shadow-outline hover:bg-stone-50'
                  >
                    Move to Inbox
                  </button>
                ) : (
                  <></>
                )}
              </div>
              {!activeItemData.archived ? (
                <div className='hidden sm:block'>
                  <button
                    onClick={handleArchiveTask}
                    className='h-6 px-2 border border-red-400 rounded-[5px] text-xs font-medium text-red-400 transition-colors duration-150 focus:shadow-outline hover:bg-red-50'
                  >
                    Archive
                  </button>
                </div>
              ) : (
                <></>
              )}
              <CloseIcon
                height={27}
                width={27}
                className='ml-3 cursor-pointer'
                onClick={updateTaskData}
              />
            </div>
          </div>
          <div>
            <div className='flex'>
              <div>
                {/* TODO: Make changes to the completed item for when it is checked off, call onChange */}
                <div className='mr-[6px] flex-none'>
                  {updFormData.completed ? (
                    <svg
                      className='w-6 h-6 hover:stroke-2'
                      fill='#09BC8A'
                      viewBox='0 0 20 20'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={handleUncompleteTask}
                    >
                      <path
                        fillRule='evenodd'
                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                        clipRule='evenodd'
                      />
                    </svg>
                  ) : (
                    <svg
                      className='w-6 h-6 stroke-1 hover:stroke-2'
                      fill='none'
                      stroke='#09BC8A'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={handleCompleteTask}
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                      />
                    </svg>
                  )}
                </div>
              </div>
              <div className='flex-1'>
                <ContentEditable
                  html={updFormData.title}
                  disabled={false}
                  onChange={onTitleChange}
                  tabIndex={0}
                  placeholder='You got this!'
                  className='text-lg leading-6
                  font-medium text-gray-900 w-full'
                  onBlur={() => {
                    if (updFormData.title !== '') {
                      setTaskEdit(false)
                    } else {
                      setUpdFormData((prevState) => ({
                        ...prevState,
                        title: 'You got this!',
                      }))
                    }
                  }}
                />
              </div>
            </div>
            <br></br>
            <hr></hr>
            <div>
              <div className='px-7 py-5'>
                <ContentEditable
                  html={updFormData.description}
                  disabled={false}
                  onChange={onDescChange}
                  tabIndex={1}
                  placeholder='+ Description'
                  className='text-gray-500 font-medium text-sm'
                />
              </div>
            </div>
            <hr></hr>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailModal
