import React, { useContext } from 'react'
import { ReactComponent as InboxIcon } from '../assets/svg/inboxIcon.svg'
import { ReactComponent as CheckboxIcon } from '../assets/svg/checkboxIcon.svg'
// import { ReactComponent as ArchivedIcon } from '../assets/svg/archiveIcon.svg'
import TaskContext from '../context/TaskContext'
import { getAuth } from 'firebase/auth'

function SideNav() {
  const { view, setView, todayTasks, loading } = useContext(TaskContext)
  const auth = getAuth()

  // Allow the user to logout
  const onLogout = () => {
    auth.signOut()
  }

  const taskLength = () => {
    if (!loading) {
      const incompleteTasks = todayTasks.filter(
        (item) => !item.data.completed && !item.data.archived
      )
      if (isNaN(incompleteTasks?.length)) {
        return 0
      } else {
        return incompleteTasks?.length
      }
    }
  }

  return (
    <div className='navContainer grid py-7'>
      <div className='navItems grid justify-center flex-col space-y-5'>
        <button
          className={
            view === 'inbox'
              ? 'navItem flex justify-center bg-gray-200 p-2.5 rounded-full'
              : 'navItem flex justify-center p-2.5'
          }
          onClick={() => setView('inbox')}
        >
          <InboxIcon width={32} height={32} />
        </button>
        <button
          className={
            view === 'today'
              ? 'navItem flex justify-center bg-slate-200 p-2.5 rounded-full'
              : 'navItem flex justify-center p-2.5'
          }
          onClick={() => setView('today')}
        >
          <div className='relative'>
            <CheckboxIcon width={32} height={32} />
            {taskLength() > 0 ? (
              <span className='e-badge e-badge-info e-badge-overlap e-badge-bottom e-badge-circle e-badge-notification'>
                {taskLength()}
              </span>
            ) : (
              <></>
            )}
          </div>
        </button>
        {/* <button
          className={
            view === 'archived'
              ? 'navItem flex justify-center bg-slate-200 p-2.5 rounded-full'
              : 'navItem flex justify-center p-2.5'
          }
          onClick={() => setView('archived')}
        >
          <ArchivedIcon width={32} height={32} />
        </button> */}
      </div>
      <div className='profileIcon grid absolute bottom-[2%] w-full justify-center'>
        <div className=''>
          <div className='flex-none'>
            <div className='dropdown dropdown-end'>
              <label tabIndex='0' className='btn btn-ghost btn-circle avatar'>
                <div className='w-10 rounded-full'>
                  <img
                    src={auth.currentUser.photoURL}
                    alt='profile'
                    referrerPolicy='no-referrer'
                  />
                </div>
              </label>
              <ul
                tabIndex='0'
                className='mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-md w-40 bottom-[100%] left-0'
              >
                <li>
                  <a href='/' type='link' onClick={onLogout}>
                    Log out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideNav
