import { useContext } from 'react'
import TaskContext from '../context/TaskContext'
import ProgressBar from './ProgressBar'

function TaskTitle() {
  // Bring in todayView state
  const { view, setConfirmReset } = useContext(TaskContext)

  const handleConfirmReset = () => {
    setConfirmReset(true)
  }

  return (
    <div className='mt-3 mb-3'>
      {view === 'inbox' ? (
        <h1>Inbox</h1>
      ) : view === 'today' ? (
        <div>
          {/* Focus view used to be called Today view... */}
          <div className='flex justify-between content-center'>
            <h1>Focus</h1>
            <button
              onClick={handleConfirmReset}
              className='bg-transparent hover:bg-blue-100 text-blue-400 font-semibold text-xs hover:text-blue-300 px-2 h-6 border border-blue-400 rounded'
            >
              Clear Tasks
            </button>
          </div>
          <ProgressBar />
        </div>
      ) : (
        <h1>Archived</h1>
      )}
    </div>
  )
}

export default TaskTitle
