import { useContext } from 'react'

// FullCalendar
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

// Components
import TaskList from '../components/TaskList'
import TaskTitle from '../components/TaskTitle'
import DetailModal from '../components/DetailModal'
import SideNav from '../components/SideNav'
import EventDetailModal from '../components/EventDetailModal'
import ConfirmModal from '../components/ConfirmModal'

// Data
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import TaskContext from '../context/TaskContext'
import GoogleContext from '../context/GoogleContext'
import CalendarContext from '../context/CalendarContext'

function Main() {
  const {
    openModal,
    tasks,
    todayTasks,
    archivedTasks,
    setTodayTasks,
    setTodayOrder,
    setTasks,
    setTaskOrder,
    setArchivedTasks,
    confirmReset,
  } = useContext(TaskContext)

  const { calendarEvents } = useContext(GoogleContext)

  const {
    handleDrop,
    handleEventResize,
    handleEventDrop,
    handleEventClick,
    openEventModal,
  } = useContext(CalendarContext)

  // React beautiful DND function to save task order when you drag a task to a different location in the same list
  // TODO: Have this done in TaskContext and then figure out a way to add it to the document column
  const handleOnDragEnd = (result) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!result.destination) {
      return
    }

    // Check to see if the user moved the task to a new location
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return
    }

    // Let order persist if the user started dragging in the inbox list
    if (result.source.droppableId === 'inbox') {
      const items = Array.from(tasks)
      const [reorderedTask] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedTask)

      // Set task state and save to local storage
      setTasks(items)
      window.localStorage.setItem('tasks', JSON.stringify(items))

      // Create task order array to then set the state
      let taskOrder = []
      items.forEach((item) => {
        taskOrder.push(item.id)
      })

      // Prepare data to save state and localStorage in taskOrder & user (which holds taskOrder DB info)
      const arrayData = {
        ...user,
        taskOrder,
      }

      setTaskOrder(taskOrder)
      window.localStorage.setItem('taskOrder', JSON.stringify([taskOrder]))
      localStorage.setItem('user', JSON.stringify(arrayData))
    }

    // Let order persist if the user started dragged in the today list
    if (result.source.droppableId === 'today') {
      const items = Array.from(todayTasks)
      const [reorderedTask] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedTask)

      setTodayTasks(items)
      localStorage.setItem('todayTasks', JSON.stringify(items))

      let todayOrder = []
      items.forEach((item) => {
        todayOrder.push(item.id)
      })

      // Prepare data to save state and localStorage in todayOrder & user (which holds todayOrder DB info)
      const arrayData = {
        ...user,
        todayOrder,
      }

      setTodayOrder(todayOrder)
      localStorage.setItem('todayOrder', JSON.stringify([todayOrder]))
      localStorage.setItem('user', JSON.stringify(arrayData))
    }

    if (result.source.droppableId === 'archived') {
      const items = Array.from(archivedTasks)
      const [reorderedTask] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedTask)

      setArchivedTasks(items)
      localStorage.setItem('archivedTasks', JSON.stringify(items))
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className='container mx-auto'>
        <div className='mainContainer flex container h-screen'>
          <div className='taskContainer w-full lg:w-[50vw] lg:mr-[20px] h-full flex'>
            <div className='sideNav w-[75px] relative'>
              <SideNav />
            </div>
            <div
              className='mainContent w-[90%] overflow-hidden'
              id='taskListMain'
            >
              <TaskTitle />
              <TaskList />
            </div>
          </div>
          <div className='calContainer overflow-hidden hidden lg:block'>
            <Droppable droppableId='calendar'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ height: '100%' }}
                >
                  <FullCalendar
                    plugins={[timeGridPlugin, interactionPlugin]}
                    initialView='timeGridDay'
                    height='100%'
                    events={calendarEvents}
                    headerToolbar={{
                      start: 'title',
                      center: '',
                      end: 'prev,today,next',
                    }}
                    titleFormat={{
                      month: 'short',
                      day: 'numeric',
                      weekday: 'short',
                    }}
                    dayHeaders={false}
                    nowIndicator={true}
                    selectable={false}
                    editable={true}
                    droppable={true}
                    scrollTime='07:00:00'
                    defaultTimedEventDuration='00:30'
                    eventClick={handleEventClick}
                    eventDrop={handleEventDrop}
                    drop={handleDrop}
                    eventResize={handleEventResize}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        {openModal ? <DetailModal /> : <></>}
        {openEventModal ? <EventDetailModal /> : <></>}
        {confirmReset ? <ConfirmModal /> : <></>}
      </div>
    </DragDropContext>
  )
}

export default Main
