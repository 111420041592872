import { useContext } from 'react'
import { serverTimestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import TaskContext from '../context/TaskContext'
import { ReactComponent as CheckCircle } from '../assets/svg/checkCircle.svg'

function TaskInput() {
  // Bring in context
  const { formData, setFormData, addTask, setTaskInput } =
    useContext(TaskContext)

  const auth = getAuth()

  const { title } = formData

  // Submit new task to database
  const onSubmit = async (e) => {
    e.preventDefault()

    // Make a copy of the task data and add timestamp to prep for db send
    const formDataCopy = {
      ...formData,
      timestamp: serverTimestamp(),
      description: '',
      archived: false,
      userRef: auth.currentUser.uid,
    }

    if (formDataCopy.title !== '') {
      addTask(formDataCopy)
    } else {
      setTaskInput(false)
    }
  }

  // Control state value for input field
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  return (
    <div className='taskItemContainer mb-[20px] p-[15px] shadow-md hover:shadow-lg border border-gray-light rounded items-center flex'>
      <div className='mr-[6px]'>
        <CheckCircle />
      </div>
      <form
        className='commentForm w-full'
        onBlur={(e) => {
          if (title === '') {
            setTaskInput(false)
          } else {
            onSubmit(e)
          }
        }}
        onSubmit={onSubmit}
      >
        <input
          className='addTaskInput'
          autoFocus
          type='text'
          id='title'
          value={title}
          onChange={onChange}
          required
          placeholder='You got this'
        />
      </form>
    </div>
  )
}

export default TaskInput
