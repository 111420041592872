import React from 'react'
import Hero from '../components/MarketingPage/Hero'
import Footer from '../components/MarketingPage/Footer'

function MarketingPage() {
  return (
    <div className='bg-white pb-8 sm:pb-12 lg:pb-12 overflow-x-hidden h-screen'>
      <Hero />
      <Footer />
    </div>
  )
}

export default MarketingPage
