import { createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

const GoogleContext = createContext()

export const GoogleProvider = ({ children }) => {
  const [calendarEvents, setCalendarEvents] = useState(() => {
    const saved = localStorage.getItem('calendarEvents')
    return saved || ''
  })
  const [syncTokens, setSyncTokens] = useState(() => {
    const saved = localStorage.getItem('syncTokens')
    return saved || []
  })
  let combinedEvents = []
  let syncTokenList = []

  useEffect(() => {
    window.gapi.load('client', () => {
      window.gapi.client.init({
        apiKey: process.env.REACT_APP_API_KEY,
        clientId: process.env.REACT_APP_GCAL_CLIENT_ID,
        prompt: 'select_account',
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        ],
        scope:
          'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events',
      })
    })
  })

  /* //////////////////////////////////////////////
  Function to call for the user's list of calendars
  ////////////////////////////////////////////// */

  const fetchCalendarList = async () => {
    try {
      const calendarList = await window.gapi.client.calendar.calendarList.list(
        {}
      )
      const items = calendarList.result.items
      return items
    } catch (error) {
      console.log(error)
    }
  }

  /* ////////////////////////////////////////////////////
  Function to call for the user's list of events
  (Note: This is used for both full and incremental sync)
  //////////////////////////////////////////////////// */
  const fetchEvents = async (calendar, incSync) => {
    // Set date parameters for how far into past/future we fetch events
    const future = new Date()
    const date = new Date()
    future.setDate(future.getDate() + 30)
    date.setDate(date.getDate() - 30)

    // If we're doing an incremental sync do an inc sync API call. If not, do a regular fetchEvent call
    if (incSync) {
      // Use localStorage data in case user has refreshed
      const syncTokens = JSON.parse(localStorage.getItem('syncTokens'))

      // Make the API call to get a list of changes that has happened on the calendar
      try {
        const incSyncCall = await window.gapi.client.calendar.events.list({
          calendarId: calendar.calendarId,
          singleEvents: true,
          syncToken: calendar.syncToken,
        })

        // Result contains any new or updated items
        const result = incSyncCall.result.items

        // Take the new syncToken that came in the payload and store it
        const newSyncToken = incSyncCall.result.nextSyncToken

        // Update existing syncToken with new one in localStorage and state
        const updatedSyncTokens = syncTokens.map((item) => {
          if (calendar.calendarId === item.calendarId && newSyncToken) {
            const newTokenData = {
              calendarId: calendar.calendarId,
              syncToken: newSyncToken,
            }

            return newTokenData
          }
          return item
        })

        // Set state and localStorage
        localStorage.setItem('syncTokens', JSON.stringify(updatedSyncTokens))
        setSyncTokens(updatedSyncTokens)

        // If there aren't any new results, do nothing, if there are then continue
        if (result.length > 0) {
          // Map through each result and existing calendarEvents. If item already exists, replace the old one.
          // If not, add it to existing calendarEvents.
          result.forEach((item) => {
            // Use localStorage data in case user has refreshed
            const localEvents = JSON.parse(
              localStorage.getItem('calendarEvents')
            )
            const found = localEvents.some((event) => event.id === item.id)
            if (found) {
              // If the event was cancelled, remove it from the calendar, if not update it
              if (item.status === 'cancelled') {
                const updatedEvents = localEvents.filter(
                  (event) => item.id !== event.id
                )

                localStorage.setItem(
                  'calendarEvents',
                  JSON.stringify(updatedEvents)
                )
                setCalendarEvents(updatedEvents)
              } else {
                const updatedEvents = localEvents.map((event) => {
                  if (item.id === event.id) {
                    const updData = {
                      ...event,
                      start: item.start.dateTime
                        ? item.start.dateTime
                        : item.start.date,
                      end: item.end.dateTime
                        ? item.end.dateTime
                        : item.end.date,
                      status: item.status,
                      title: item.summary,

                      description: item.description,
                      location: item.location,
                      organizer: item.organizer.email,
                    }
                    return updData
                  }
                  return event
                })

                localStorage.setItem(
                  'calendarEvents',
                  JSON.stringify(updatedEvents)
                )
                setCalendarEvents(updatedEvents)
              }
            } else {
              if (item.source && item.source.title.includes('Poetic -')) {
                // Poetic created tasks
                const eventData = {
                  id: item.id,
                  calID: calendar.calendarId,
                  status: item.status,
                  created: item.created,
                  title: item.summary,
                  description: item.description,
                  location: item.location,
                  creator: item.creator.email,
                  organizer: item.organizer.email,
                  backgroundColor: '#72C984',
                  borderColor: '#72C984',
                  start: item.start.dateTime
                    ? item.start.dateTime
                    : item.start.date,
                  end: item.end.dateTime ? item.end.dateTime : item.end.date,
                  allDay: item.start.date || item.end.date ? true : false,
                  attendees: item.attendees ? item.attendees : false,
                  editable: true,
                  startEditable: true,
                  durationEditable: true,
                  type: 'task',
                  source: item.source,
                }

                localStorage.setItem(
                  'calendarEvents',
                  JSON.stringify([...localEvents, eventData])
                )
                setCalendarEvents([...localEvents, eventData])
              } else {
                // Non-poetic tasks (existing calendar events)
                const eventData = {
                  id: item.id,
                  calID: calendar,
                  status: item.status,
                  created: item.created,
                  title: item.summary,
                  backgroundColor: '#85ADF9',
                  borderColor: '#85ADF9',
                  description: item.description,
                  location: item.location,
                  confLink: item.hangoutLink,
                  creator: item.creator.email,
                  organizer: item.organizer.email,
                  start: item.start.dateTime
                    ? item.start.dateTime
                    : item.start.date,
                  end: item.end.dateTime ? item.end.dateTime : item.end.date,
                  allDay: item.start.date || item.end.date ? true : false,
                  attendees: item.attendees ? item.attendees : false,
                  editable:
                    item.guestsCanModify || item.creator.self ? true : false,
                  startEditable:
                    item.guestsCanModify || item.creator.self ? true : false,
                  durationEditable:
                    item.guestsCanModify || item.creator.self ? true : false,
                  type: 'event',
                  source: 'calendar',
                }

                localStorage.setItem(
                  'calendarEvents',
                  JSON.stringify([...localEvents, eventData])
                )
                setCalendarEvents([...localEvents, eventData])
              }
            }
          })
        }
      } catch (error) {
        if (error.status === '410') {
          fetchInitialEvents()
        }
      }
    } else {
      try {
        // API request to fetch events
        const eventCall = await window.gapi.client.calendar.events.list({
          calendarId: calendar.id,
          timeMin: date.toISOString(),
          timeMax: future.toISOString(),
          showDeleted: false,
          singleEvents: true,
        })

        const events = eventCall.result.items
        const nextSyncToken = eventCall.result.nextSyncToken
        const syncTokenData = {
          calendarId: calendar.id,
          syncToken: nextSyncToken,
        }

        // Put all syncTokenData object into the defined syncTokenList
        syncTokenList.push(syncTokenData)

        // Set syncToken data into state and localStorage so we can do incremental sync

        setSyncTokens(syncTokenList)
        localStorage.setItem('syncTokens', JSON.stringify(syncTokenList))

        // Loop through the returned data and transform them into workable data so FullCalendar can use it
        events.forEach((item) => {
          if (item.source && item.source.title.includes('Poetic -')) {
            // Poetic created tasks
            const eventData = {
              id: item.id,
              calID: calendar.id,
              status: item.status,
              created: item.created,
              title: item.summary,
              description: item.description,
              location: item.location,
              creator: item.creator.email,
              organizer: item.organizer.email,
              backgroundColor: '#72C984',
              borderColor: '#72C984',
              start: item.start.dateTime
                ? item.start.dateTime
                : item.start.date,
              end: item.end.dateTime ? item.end.dateTime : item.end.date,
              allDay: item.start.date || item.end.date ? true : false,
              attendees: item.attendees ? item.attendees : false,
              editable: true,
              startEditable: true,
              durationEditable: true,
              type: 'task',
              source: item.source,
            }

            // Put all the events from all the calendars into one list that the we will use
            // to set the state and localStorage
            combinedEvents.push(eventData)
          } else {
            // Non-poetic tasks (existing calendar events)
            const eventData = {
              id: item.id,
              calID: calendar.id,
              status: item.status,
              created: item.created,
              title: item.summary,
              description: item.description,
              backgroundColor: '#85ADF9',
              borderColor: '#85ADF9',
              location: item.location,
              confLink: item.hangoutLink,
              creator: item.creator.email,
              organizer: item.organizer.email,
              start: item.start.dateTime
                ? item.start.dateTime
                : item.start.date,
              end: item.end.dateTime ? item.end.dateTime : item.end.date,
              allDay: item.start.date || item.end.date ? true : false,
              attendees: item.attendees ? item.attendees : false,
              editable:
                item.guestsCanModify || item.creator.self ? true : false,
              startEditable:
                item.guestsCanModify || item.creator.self ? true : false,
              durationEditable:
                item.guestsCanModify || item.creator.self ? true : false,
              type: 'event',
              source: 'calendar',
            }

            // Put all the events from all the calendars into one list that the we will use
            // to set the state and localStorage
            combinedEvents.push(eventData)
          }
        })

        return combinedEvents
      } catch (error) {
        console.log(error)
      }
    }
  }

  /* //////////////////////////////////////////////
  Function to insert a new calendar event into the 
  user's Google Calendar
  ////////////////////////////////////////////// */
  const insertEvent = async (resource, taskId) => {
    try {
      await window.gapi.client.calendar.events
        .insert({
          calendarId: 'primary',
          resource: resource,
        })
        .then((response) => {
          // Pull calendarEvents from localStorage (because it sets faster than setState)
          const localCalEvents = JSON.parse(
            localStorage.getItem('calendarEvents')
          )
          // Map through events and add the Google Calendar Event ID. This allows us to have multiple events per task
          const updatedEvents = localCalEvents.map((item) => {
            if (item.id === taskId) {
              const updatedData = {
                ...item,
                id: response.result.id,
                taskRef: taskId,
              }

              return updatedData
            }
            return item
          })
          setCalendarEvents(updatedEvents)
          localStorage.setItem('calendarEvents', JSON.stringify(updatedEvents))
        })
    } catch (error) {
      console.log(error)
      // If there's an error here... Pop up a toast and remove the event from the calendarEvents.
      const localCalEvents = JSON.parse(localStorage.getItem('calendarEvents'))
      const updatedEvents = localCalEvents.filter((item) => item.id !== taskId)
      setCalendarEvents(updatedEvents)
      localStorage.setItem('calendarEvents', JSON.stringify(updatedEvents))
      toast.error('Sorry, we were not able to add the event to your calendar')
    }
  }

  /* //////////////////////////////////////////////
  Function to update an existing google calendar event
  in the event details have changed from Poetic
  and need to be updated to Google
  ////////////////////////////////////////////// */
  const updateEvent = async (calendarId, eventId, resource) => {
    try {
      await window.gapi.client.calendar.events.update({
        calendarId: calendarId,
        eventId: eventId,
        resource: resource,
      })
    } catch (error) {
      console.log(error)
    }
  }

  /* //////////////////////////////////////////////
  Function to delete an event from the user's 
  Google Calendar
  ////////////////////////////////////////////// */
  const deleteEvent = async (calendarId, eventId) => {
    try {
      await window.gapi.client.calendar.events.delete({
        calendarId: calendarId,
        eventId: eventId,
      })
    } catch (error) {
      console.log(error)
    }
  }

  /* /////////////////////////////////////////////////
  Function to remove an event from the user's calendar
  ///////////////////////////////////////////////// */
  const removeCalendarEvent = async (event) => {
    const calendarEvents = JSON.parse(localStorage.getItem('calendarEvents'))

    // Call the delete function from the Google API
    try {
      await deleteEvent(event.calID, event.id).then((response) => {
        const updatedCalendarEvents = calendarEvents.filter(
          (item) => item.id !== event.id
        )
        setCalendarEvents(updatedCalendarEvents)
        localStorage.setItem(
          'calendarEvents',
          JSON.stringify(updatedCalendarEvents)
        )
      })
    } catch (error) {
      console.log(error)
    }
  }

  /* //////////////////////////////////////////////
  Function that is called when a user initially logs
  in or clears their cookies to get up to date events
  ////////////////////////////////////////////// */
  const fetchInitialEvents = async () => {
    try {
      await fetchCalendarList().then((response) => {
        response.forEach(async (item) => {
          try {
            // Get the events. Set the item and note that we're not doing an incremental sync call.
            await fetchEvents(item, false)
          } catch (error) {
            console.log(error)
          }
        })
      })
    } catch (error) {
      console.log(error)
    }

    // Need to set a timeout because I'm pushing items into an empty array asynchronously.
    setTimeout(() => {
      setCalendarEvents(combinedEvents)
      localStorage.setItem('calendarEvents', JSON.stringify(combinedEvents))
    }, 1500)
  }

  /* //////////////////////////////////////////////
  Function to do an incremental sync 
  (update calendar events and any changes)
  This also runs every 30 seconds
  ////////////////////////////////////////////// */

  const incrementalSync = async () => {
    // In case there aren't any syncTokens in localStorage, just do a full refresh of the data
    if (
      !localStorage.getItem('syncTokens') ||
      !localStorage.getItem('calendarEvents')
    ) {
      fetchInitialEvents()
    } else {
      // Get the existing syncTokens in localStorage to pass in the calendarId and current syncToken
      const syncTokens = JSON.parse(localStorage.getItem('syncTokens'))
      syncTokens.forEach(async (item) => {
        try {
          // Get the updated events. Set the item and note that we're doing an incremental sync call.
          await fetchEvents(item, true)
        } catch (error) {
          console.log(error)
        }
      })
    }
  }

  const watchEvents = async () => {
    try {
      await fetchCalendarList().then((response) => {
        response.forEach(async (item) => {
          try {
            const response = await window.gapi.client.calendar.events.watch({
              calendarId: item.id,
              id: uuidv4(),
              type: 'web_hook',
              address: 'https://poetical-12953.firebaseapp.com',
            })

            console.log(response)
          } catch (error) {
            console.log(error)
          }
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <GoogleContext.Provider
      value={{
        calendarEvents,
        setCalendarEvents,
        fetchInitialEvents,
        fetchEvents,
        fetchCalendarList,
        insertEvent,
        updateEvent,
        deleteEvent,
        removeCalendarEvent,
        incrementalSync,
        watchEvents,
      }}
    >
      {children}
    </GoogleContext.Provider>
  )
}

export default GoogleContext
