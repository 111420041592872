import { createContext, useContext, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

// Data
import TaskContext from './TaskContext'
import GoogleContext from './GoogleContext'

const CalendarContext = createContext()

export const CalendarProvider = ({ children }) => {
  const [activeEventData, setActiveEventData] = useState(null)
  const [openEventModal, setOpenEventModal] = useState(false)

  const { commitTask, setActiveItemData, setOpenModal } =
    useContext(TaskContext)
  const { calendarEvents, setCalendarEvents, insertEvent, updateEvent } =
    useContext(GoogleContext)

  /* /////////////////////////////////
  FULLCALENDAR EVENT HANDLER FUNCTIONS
  ///////////////////////////////// */

  // Function that handles dragging a task onto the calendar
  const handleDrop = async (dropInfo) => {
    const sourceData = {
      title: `Poetic - ${dropInfo.draggedEl.getAttribute('data-id')}`,
      url: 'https://trypoetic.com',
    }

    const newEvent = {
      id: dropInfo.draggedEl.getAttribute('data-id'),
      title: dropInfo.draggedEl.getAttribute('title'),
      description: dropInfo.draggedEl.getAttribute('data-description'),
      completed: dropInfo.draggedEl.getAttribute('data-completed'),
      archived: dropInfo.draggedEl.getAttribute('data-archived'),
      column: dropInfo.draggedEl.getAttribute('data-column'),
      userRef: dropInfo.draggedEl.getAttribute('data-userref'),
      backgroundColor: '#72C984',
      borderColor: '#72C984',
      calID: 'primary',
      type: 'task',
      start: dropInfo.dateStr,
      end: moment(dropInfo.dateStr).add(30, 'minutes').toISOString(),
      duration: '00:30',
      source: sourceData,
      taskRef: dropInfo.draggedEl.getAttribute('data-id'),
    }

    // Set calendar state so they surface on the events
    setCalendarEvents((calendarEvents) => [...calendarEvents, newEvent])
    localStorage.setItem(
      'calendarEvents',
      JSON.stringify([...calendarEvents, newEvent])
    )

    // Commit the task and move it to Today column
    commitTask(newEvent)

    // Prepare event data to be inserted into user's Google Calendar
    const calEvent = {
      summary: dropInfo.draggedEl.getAttribute('title'),
      description: dropInfo.draggedEl.getAttribute('data-description'),
      start: {
        dateTime: dropInfo.dateStr,
      },
      end: {
        dateTime: moment(dropInfo.dateStr).add(30, 'minutes').toISOString(),
      },
      source: {
        title: `Poetic - ${dropInfo.draggedEl.getAttribute('data-id')}`,
        url: 'https://trypoetic.com',
      },
      visibility: 'default',
    }

    // Insert event into user's Google Calendar
    await insertEvent(calEvent, newEvent.id)
  }

  // When a user drags the duration of an event, update the start and end times
  const handleEventResize = async (eventResizeInfo) => {
    const updatedEvents = calendarEvents.map((item) => {
      if (item.id === eventResizeInfo.event.id) {
        // Get variables to calculate duration of new event
        const startTime = moment(eventResizeInfo.event.startStr)
        const endTime = moment(eventResizeInfo.event.endStr)
        const duration = moment.duration(endTime.diff(startTime))

        // Duration in minutes
        const minutes = parseInt(duration.asMinutes())

        // Set new data of the object
        const dataCopy = {
          ...item,
          start: eventResizeInfo.event.startStr,
          end: eventResizeInfo.event.endStr,
          duration: minutes,
        }

        // Set arguments to send to the updateEvent Google API function
        const resource = {
          summary: item.title,
          description: item.description,
          start: {
            dateTime: eventResizeInfo.event.startStr,
          },
          end: {
            dateTime: eventResizeInfo.event.endStr,
          },
          visibility: 'default',
        }

        // Call update event in Google Calendar API
        updateEvent(item.calID, item.id, resource)

        return dataCopy
      }

      return item
    })

    // Set state and localStorage
    setCalendarEvents(updatedEvents)
    localStorage.setItem('calendarEvents', JSON.stringify(updatedEvents))
  }

  // Function that is called whenever a task is moved within the same calendar
  const handleEventDrop = (eventDropInfo) => {
    const updatedEvents = calendarEvents.map((item) => {
      if (item.id === eventDropInfo.event.id) {
        const dataCopy = {
          ...item,
          start: eventDropInfo.event.startStr,
          end: eventDropInfo.event.endStr,
        }

        const resource = {
          summary: item.title,
          description: item.description,
          start: {
            dateTime: eventDropInfo.event.startStr,
          },
          end: {
            dateTime: eventDropInfo.event.endStr,
          },
          visibility: 'default',
        }

        // Call update event in Google Calendar API
        updateEvent(item.calID, item.id, resource)
        return dataCopy
      }
      return item
    })

    // Set state and localStorage
    setCalendarEvents(updatedEvents)
    localStorage.setItem('calendarEvents', JSON.stringify(updatedEvents))
  }

  // Populate detail modal data once you click on an event in the calendar
  const activeItemModal = (task) => {
    setActiveItemData({
      ...task.data,
      id: task.id,
    })
    setOpenModal(true)
  }

  const activeEventModal = (calEvent) => {
    setActiveEventData({
      ...calEvent,
      id: calEvent.id,
    })

    setOpenEventModal(true)
  }

  // Surface detail modal when user clicks on event in calendar
  const handleEventClick = (info) => {
    const calendarEvents = JSON.parse(localStorage.getItem('calendarEvents'))
    const tasks = JSON.parse(localStorage.getItem('tasks'))
    const todayTasks = JSON.parse(localStorage.getItem('todayTasks'))
    const archivedTasks = JSON.parse(localStorage.getItem('archivedTasks'))

    // Get the relevant calendarEvent details
    const calEventArray = calendarEvents.filter(
      (item) => info.event.id === item.id
    )

    const calEvent = calEventArray[0]

    // If it's a Poetic Task then get the relevant task details
    if (calEvent.type === 'task') {
      // Combine the tasks into one array to grab the right task no matter what list it's in
      const combinedTaskArray = []
      todayTasks.forEach((item) => {
        combinedTaskArray.push(item)
      })
      tasks.forEach((item) => {
        combinedTaskArray.push(item)
      })
      archivedTasks.forEach((item) => {
        combinedTaskArray.push(item)
      })

      const calEventTask = calEvent.source.title.slice(9)
      console.log(calEventTask)

      // Find the relevant task using the taskRef in calTask
      const sourceTaskArray = combinedTaskArray.filter(
        (item) => item.id === calEventTask
      )
      const sourceTask = sourceTaskArray[0]

      activeItemModal(sourceTask)
    } else {
      activeEventModal(calEvent)
    }
  }

  return (
    <CalendarContext.Provider
      value={{
        openEventModal,
        activeEventData,
        handleDrop,
        handleEventResize,
        handleEventDrop,
        handleEventClick,
        setOpenEventModal,
        setActiveEventData,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}

export default CalendarContext
