import React from 'react'
import { useContext } from 'react'
import TaskContext from '../context/TaskContext'
import TaskItem from '../components/TaskItem'
import TaskInput from './TaskInput'
// import Spinner from './Spinner'
import { Droppable } from 'react-beautiful-dnd'
import { ReactComponent as AddCTA } from '../assets/svg/AddCTA.svg'
import { ReactComponent as DogDoodle } from '../assets/svg/dogDoodle.svg'
import { ReactComponent as IceCreamDoodle } from '../assets/svg/iceCreamDoodle.svg'
import { ReactComponent as Spinner } from '../assets/svg/pacManGif.svg'

function TaskList() {
  // Bring in context
  const {
    tasks,
    loading,
    todayTasks,
    taskInput,
    setTaskInput,
    view,
    archivedTasks,
  } = useContext(TaskContext)

  return loading ? (
    <Spinner />
  ) : (
    <div className='h-full'>
      {view === 'today' ? (
        /* Today View */

        <div
          className='taskItemList overflow-auto h-full pb-[75px]'
          id='taskList'
        >
          {todayTasks.length > 0 ? (
            <Droppable droppableId='today'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {todayTasks.map((task, index) => (
                    <TaskItem
                      key={task.id}
                      task={task.data}
                      id={task.id}
                      index={index}
                      column='today'
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <div className='border border-dashed border-gray-400 border-4 rounded-md h-100% mt-3 text-center grid place-items-center'>
              <DogDoodle className='h-[200px] w-full mt-6' />
              <div className='p-5 w-full lg:w-[70%]'>
                <h1>No tasks for the day</h1>
                <p className='mt-2 mb-4 mx-4'>
                  Go to your Inbox view and drag tasks into your calendar and
                  they'll appear here
                </p>
              </div>
            </div>
          )}
        </div>
      ) : view === 'inbox' ? (
        <div className='h-full'>
          <div className='flex justify-between content-center'>
            <p className='taskListTitle text-sm font-bold'>
              Total tasks: {tasks?.length}
            </p>
            <AddCTA
              className='mb-[20px] hover:shadow-md content-center addCTA'
              onClick={() => {
                setTaskInput(true)
              }}
            />
          </div>
          {taskInput ? <TaskInput /> : <></>}
          <div
            className='taskItemList overflow-auto h-full pb-[75px]'
            id='taskList'
          >
            {tasks?.length > 0 ? (
              <>
                <Droppable droppableId='inbox'>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {tasks.map((task, index) => (
                        <TaskItem
                          key={task.id}
                          task={task.data}
                          id={task.id}
                          index={index}
                          column='inbox'
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </>
            ) : (
              <div className='border border-dashed border-gray-400 border-4 rounded-md h-100% mt-3 text-center grid place-items-center'>
                <IceCreamDoodle className='h-[200px] w-full mt-6' />
                <div className='p-5 w-full lg:w-[80%]'>
                  <h1>Hi there!</h1>
                  <p className='mt-2 mb-4 mx-4'>
                    Drag tasks you intend to do today into your calendar. Then
                    visit on your "Focus" view for a distraction-free working
                    session.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className='taskItemList overflow-auto h-full pb-[75px]'
          id='taskList'
        >
          {archivedTasks.length > 0 ? (
            <Droppable droppableId='archived'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {archivedTasks.map((task, index) => (
                    <TaskItem
                      key={task.id}
                      task={task.data}
                      id={task.id}
                      index={index}
                      column='archived'
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            // <div className='border border-dashed border-gray-400 border-4 rounded-md h-100% mt-3 text-center grid place-items-center'>
            //   <DogDoodle className='h-[200px] mt-6' />
            //   <div className='p-5 w-[70%]'>
            //     <h1>Nothing archived yet!</h1>
            //     <p className='mt-2 mb-4 mx-4'>
            //       Here's where you'll find tasks you already completed or don't
            //       feel like looking at anymore
            //     </p>
            //   </div>
            // </div>
            <></>
          )}
        </div>
      )}
    </div>
  )
}

export default TaskList
