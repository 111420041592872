import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Main from './pages/Main'
import Navbar from './components/Navbar'
import PrivateRoute from './components/PrivateRoute'
import MarketingPage from './pages/MarketingPage'
import { GoogleProvider } from './context/GoogleContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PrivacyPolicy from './pages/PrivacyPolicy'

function App() {
  return (
    <>
      <GoogleProvider>
        <Router>
          <Routes>
            <Route
              path='/home'
              element={
                <PrivateRoute>
                  <Navbar />
                  <Main />
                </PrivateRoute>
              }
            />
            <Route path='/' element={<MarketingPage />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </GoogleProvider>
      <ToastContainer />
    </>
  )
}

export default App
