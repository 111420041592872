import { useContext } from 'react'
import TaskContext from '../context/TaskContext'
import { Draggable } from 'react-beautiful-dnd'

function TaskItem({ task, id, index, column }) {
  const { completeTask, uncompleteTask, setActiveItemData, setOpenModal } =
    useContext(TaskContext)

  const activeItemModal = () => {
    setOpenModal(true)
    setActiveItemData({
      ...task,
      id: id,
      column: column,
    })
  }

  // Mark task as complete
  const handleCompleteTask = (e) => {
    // Prevent the parent modal from opening when clicking on the complete icon
    e.stopPropagation()

    // Call the completeTask function and pass in the arguments
    if (!task.archived) {
      completeTask(task, id, column)
    }
  }

  // Unmark task as complete
  const handleUncompleteTask = (e) => {
    // Prevent the parent modal from opening when clicking on the complete icon
    e.stopPropagation()

    // Call the completeTask function and pass in the arguments
    if (!task.archived) {
      uncompleteTask(task, id, column)
    }
  }

  // Remove drop animation if dropped onto the calendar
  const calDropStyle = (style, snapshot) => {
    if (!snapshot.isDropAnimating || snapshot.draggingOver !== 'calendar') {
      return style
    } else {
      return {
        ...style,
        transitionDuration: `0.001s`,
      }
    }
  }

  const uniqueId = id + column

  return (
    <Draggable draggableId={uniqueId} index={index}>
      {(provided, snapshot) => (
        <div
          className={
            task.completed
              ? 'bg-gray-200 taskItemContainer mb-[20px] p-[15px] shadow-md hover:shadow-lg border border-gray-light rounded items-center draggable'
              : 'bg-white taskItemContainer mb-[20px] p-[15px] shadow-md hover:shadow-lg border border-gray-light rounded items-center draggable'
          }
          onClick={activeItemModal}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={calDropStyle(provided.draggableProps.style, snapshot)}
          title={task.title}
          data-description={task.description}
          data-completed={task.completed ? 1 : 0}
          data-archived={task.archived ? 1 : 0}
          data-id={id}
          data-userref={task.userRef}
          data-column={column}
        >
          <div className='taskListItem flex items-center'>
            <div className='mr-[6px]'>
              {task.completed ? (
                <svg
                  className='w-6 h-6 hover:stroke-2'
                  fill='#09BC8A'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={handleUncompleteTask}
                >
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg
                  className='w-6 h-6 stroke-1 hover:stroke-2'
                  fill='none'
                  stroke='#09BC8A'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={handleCompleteTask}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                  />
                </svg>
              )}
            </div>
            <div
              className={
                task.completed
                  ? 'taskTitleCompleted content-center truncate'
                  : 'taskTitle content-center truncate'
              }
            >
              {task.title}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default TaskItem
