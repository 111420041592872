import { useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from 'firebase/auth'
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import GoogleContext from '../context/GoogleContext'
import { ReactComponent as GoogleIcon } from '../assets/svg/icons8-google.svg'

function OAuth() {
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setUser] = useState(() => {
    const saved = localStorage.getItem('user')
    return saved || ''
  })

  const { fetchInitialEvents } = useContext(GoogleContext)

  const onGoogleClick = async () => {
    try {
      const auth = getAuth()
      new GoogleAuthProvider()
      const googleAuth = window.gapi.auth2.getAuthInstance()
      const googleUser = await googleAuth.signIn()
      const token = googleUser.getAuthResponse().id_token
      const credential = GoogleAuthProvider.credential(token)

      const result = await signInWithCredential(auth, credential).catch(
        (error) => {
          toast.error('Could not authorize with Google')
        }
      )

      const user = result.user

      // Get initial events from their calendar
      await fetchInitialEvents()

      // Check if user exists in db already
      const docRef = doc(db, 'users', user.uid)
      const docSnap = await getDoc(docRef)

      // Unsure why, but serverTimestamp() isn't working properly when throwing this
      // variable in setDoc()
      const initialData = {
        name: user.displayName,
        email: user.email,
        timestamp: serverTimestamp(),
      }

      // If user doesn't exist, create user and set their info to localStorage
      if (!docSnap.exists()) {
        await setDoc(doc(db, 'users', user.uid), initialData).then(() => {
          localStorage.setItem('user', JSON.stringify(initialData))
        })
      } else {
        // If it does exist, set their info to localStorage
        localStorage.setItem('user', JSON.stringify(docSnap.data()))
      }
      navigate('/home')
    } catch (error) {
      toast.error('Could not authorize with Google')
      console.log(error)
    }
  }

  return (
    <div className='mx-auto'>
      <div
        className='flex rounded-md bg-[#4285f4] p-1 h-[56px] w-[256px] cursor-pointer'
        onClick={onGoogleClick}
      >
        <div className='rounded-[2px] bg-white p-3'>
          <GoogleIcon className='w-7 h-7' />
        </div>
        <div className='uppercase text-white font-semibold px-3 py-3 text-[14px] '>
          Sign {user ? 'in' : 'up'} with Google
        </div>
      </div>
    </div>
  )
}

export default OAuth
