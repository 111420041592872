import React, { useContext } from 'react'
import TaskContext from '../context/TaskContext'

function ProgressBar() {
  const { todayTasks, loading } = useContext(TaskContext)

  const progressPercentage = () => {
    if (!loading) {
      const completedTasks = todayTasks.filter((item) => item.data.completed)
      if (isNaN((completedTasks?.length / todayTasks?.length) * 100)) {
        return 0
      } else {
        return (completedTasks?.length / todayTasks?.length) * 100
      }
    }
  }

  return (
    <div>
      <div className='w-full bg-gray-200 h- 2 my-2 rounded-full'>
        <div
          className='bg-emerald-500 h-2 rounded-full'
          style={{ width: `${progressPercentage()}%` }}
        ></div>
      </div>
    </div>
  )
}

export default ProgressBar
