import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import { TaskProvider } from '../context/TaskContext'
import { CalendarProvider } from '../context/CalendarContext'
import { ReactComponent as Spinner } from '../assets/svg/pacManGif.svg'
// import Spinner from './Spinner'

// This checks if the user is logged in. If they're not, they'll get redirected to the outlet (the nested route on App.js)
function PrivateRoute({ children }) {
  const { loggedIn, checkingStatus } = useAuthStatus()

  if (checkingStatus) {
    return <Spinner />
  }

  return loggedIn ? (
    <TaskProvider>
      <CalendarProvider>{children}</CalendarProvider>
    </TaskProvider>
  ) : (
    <Navigate to='/sign-up' />
  )
}

export default PrivateRoute
