import React, { useContext } from 'react'
import CalendarContext from '../context/CalendarContext'
import moment from 'moment'
import Linkify from 'linkify-react'

// Icons
import { ReactComponent as TicketIcon } from '../assets/svg/ticketIcon.svg'
import { ReactComponent as CalendarIcon } from '../assets/svg/calendarIcon.svg'
import { ReactComponent as ClockIcon } from '../assets/svg/clockIcon.svg'
import { ReactComponent as VideoIcon } from '../assets/svg/videoIcon.svg'
import { ReactComponent as LocationIcon } from '../assets/svg/locationIcon.svg'
import { ReactComponent as DescriptionIcon } from '../assets/svg/descriptionIcon.svg'
import { ReactComponent as CloseIcon } from '../assets/svg/closeIcon.svg'
import GoogleContext from '../context/GoogleContext'

function EventDetailModal() {
  const { setOpenEventModal, activeEventData } = useContext(CalendarContext)
  const { removeCalendarEvent } = useContext(GoogleContext)

  const handleOffClick = () => {
    setOpenEventModal(false)
  }

  const handleDeleteTask = () => {
    removeCalendarEvent(activeEventData)
    setOpenEventModal(false)
  }

  const handleConfCall = () => {
    window.open(activeEventData.confLink, '_blank')
  }

  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center min-h-screen md:pt-4 md:px-4 md:pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
          onClick={handleOffClick}
        ></div>
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        >
          &#8203;
        </span>
        <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full h-screen lg:w-[50%] lg:h-[90vh] sm:px-6 sm:pt-3 sm:pb-6  '>
          <div className='nav pb-5 flex justify-between'>
            <div className='grid content-center text-xs text-stone-400'>
              <div>
                Status:{' '}
                <span className='font-medium'>{activeEventData.status}</span>
              </div>
            </div>
            {activeEventData.editable ? (
              <div className='flex'>
                <div>
                  <button
                    onClick={handleDeleteTask}
                    className='h-6 px-2 border border-red-400 rounded-[5px] text-xs font-medium text-red-400 transition-colors duration-150 focus:shadow-outline hover:bg-red-50'
                  >
                    Remove
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            <CloseIcon
              height={27}
              width={27}
              className='ml-3 cursor-pointer'
              onClick={handleOffClick}
            />
          </div>
          <div>
            <div className='flex'>
              <div className='flex-1 text-lg'>{activeEventData.title}</div>
            </div>
            <br></br>
            <hr></hr>
            <div>
              {activeEventData.attendees &&
              activeEventData.attendees[0].self ? (
                <div className='py-3 text-sm flex'>
                  <TicketIcon width={20} height={20} className='mr-3' />
                  {activeEventData.attendees[0].responseStatus === 'accepted'
                    ? 'Attending'
                    : activeEventData.attendees[0].responseStatus ===
                      'tentative'
                    ? 'Tentative'
                    : activeEventData.attendees[0].responseStatus === 'declined'
                    ? 'Declined'
                    : 'Needs Action'}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {!activeEventData.allday ? (
                <div className='py-3 text-sm flex'>
                  <CalendarIcon width={17} height={17} className='mr-3' />
                  {moment(activeEventData.start).format('dddd, MMMM Do YYYY')}
                </div>
              ) : (
                <div className='px-5 py-3 text-sm'>All day</div>
              )}
            </div>
            <div>
              {!activeEventData.allday ? (
                <div className='flex py-3 text-sm'>
                  <ClockIcon width={20} height={20} className='mr-3' />
                  {`${moment(activeEventData.start).format('h:mma')} - ${moment(
                    activeEventData.end
                  ).format('h:mma')}`}
                </div>
              ) : (
                <div className='px-5 py-3 text-sm'>All day</div>
              )}
            </div>
            <div>
              {activeEventData.location ? (
                <div className='flex py-3 text-sm'>
                  <LocationIcon width={20} height={20} className='mr-3' />
                  <Linkify tagName='p'>{activeEventData.location}</Linkify>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {activeEventData.confLink ? (
                <div className='flex'>
                  <VideoIcon width={20} height={20} className='mr-3 my-auto' />
                  <button
                    onClick={handleConfCall}
                    className='py-3 text-sm text-blue-500 cursor-pointer'
                  >
                    Join Google Meet
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {activeEventData.description ? (
                <div className='flex py-3 '>
                  <DescriptionIcon width={20} height={20} className='mr-3' />
                  <div className='flex-1 text-[13px]'>
                    <Linkify tagName='p'>{activeEventData.description}</Linkify>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr></hr>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventDetailModal
