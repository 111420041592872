import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
      <footer className='bg-white' aria-labelledby='footer-heading'>
        <h2 id='footer-heading' className='sr-only'>
          Footer
        </h2>
        <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
          <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
            <div className='space-y-8 xl:col-span-1'>
              <p className='text-gray-500 text-base'>
                Creating projects to pass the time since like March 2022.
              </p>
            </div>
            <div className='mt-12 md:mt-0'>
              <h3 className='text-sm font-semibold text-gray-400 tracking-wider uppercase'>
                Legal
              </h3>
              <ul className='mt-4 space-y-4'>
                <li>
                  <Link
                    to='/privacy'
                    className='text-base text-gray-500 hover:text-gray-900'
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className='mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2'>
              <div className='md:grid md:grid-cols-2 md:gap-8'></div>
              <div className='md:grid md:grid-cols-2 md:gap-8'></div>
            </div>
          </div>
          <div className='mt-12 border-t border-gray-200 pt-8'>
            <p className='text-base text-gray-400 xl:text-center'>
              &copy; 2022 Poetic, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
