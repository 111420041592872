import OAuth from '../OAuth'
import video from '../../assets/webm/marketingPageDemo.webm'
import screenshot from '../../assets/png/appScreenshot.png'
import { ReactComponent as PoeticLogo } from '../../assets/svg/poeticLogo.svg'

function Hero() {
  return (
    <div className='relative pt-6 sm:pb-24 lg:pb-32'>
      <div>
        <div className='pt-4 sm:pt-4 lg:relative lg:py-20 '>
          <div className='relative md:ml-auto lg:ml-[8%] max-w-md px-4 sm:max-w-3xl sm:px-3 lg:px-1 lg:max-w-4xl lg:grid lg:grid-cols-2 lg:gap-24'>
            <div>
              <div className='flex'>
                <PoeticLogo className='h-12 w-auto' />
                <p className='text-[32px] ml-2 font-semibold -m-[4px] text-[#707070]'>
                  poetic
                </p>
              </div>
              <div className='mt-20'>
                <div className='mt-6 sm:max-w-xl'>
                  <h1 className='text-5xl font-extrabold text-gray-900 tracking-tight'>
                    To-dos you’ll actually do
                  </h1>
                  <p className='mt-6 text-xl text-gray-500'>
                    Create to-dos and drag them onto your calendar to dedicate
                    working time in between meetings.
                  </p>
                  <div className='mt-6'>
                    <OAuth />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='sm:mx-auto sm:max-w-3xl sm:px-6'>
            <div className='py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:-right-8 lg:w-7/12 lg:h-full'>
              <div className='hidden md:block'>
                <div className='absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full'></div>
                <svg
                  className='absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0'
                  width='404'
                  height='392'
                  fill='none'
                  viewBox='0 0 404 392'
                >
                  <defs>
                    <pattern
                      id='837c3e70-6c3a-44e6-8854-cc48c737b659'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='404'
                    height='392'
                    fill='url(#837c3e70-6c3a-44e6-8854-cc48c737b659)'
                  />
                </svg>
              </div>
              <div className='hidden relative pl-4 -mr-40 sm:mx-auto sm:max-w-4xl sm:px-0 md:block lg:max-w-none lg:h-[full] lg:pl-12'>
                <video
                  loop={true}
                  autoPlay='autoplay'
                  muted={true}
                  className='w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-full lg:max-w-none'
                >
                  <source src={video} type='video/webm' />
                </video>
              </div>
              <div className='relative md:hidden'>
                <img src={screenshot} alt='Poetic' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
