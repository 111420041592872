import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { db } from '../firebase.config'
import {
  addDoc,
  collection,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  doc,
  setDoc,
} from 'firebase/firestore'
import { Draggable } from '@fullcalendar/interaction'
import AuthContext from './GoogleContext'
import { toast } from 'react-toastify'

const TaskContext = createContext()

export const TaskProvider = ({ children }) => {
  const auth = getAuth()
  const isMounted = useRef(true)

  const { setCalendarEvents, calendarEvents, incrementalSync } =
    useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [confirmReset, setConfirmReset] = useState(false)
  const [tasks, setTasks] = useState(() => {
    const saved = localStorage.getItem('tasks')
    return saved || ''
  })
  // taskOrder saves the array that the order should be in. taskListOrder is the sorted array of tasks.
  const [taskOrder, setTaskOrder] = useState(() => {
    const saved = localStorage.getItem('taskOrder')
    return saved || ''
  })
  const [todayOrder, setTodayOrder] = useState(() => {
    const saved = localStorage.getItem('todayOrder')
    return saved || ''
  })
  const [todayTasks, setTodayTasks] = useState(() => {
    const saved = localStorage.getItem('todayTasks')
    return saved || ''
  })
  const [archivedTasks, setArchivedTasks] = useState(() => {
    const saved = localStorage.getItem('archivedTasks')
    return saved || ''
  })
  const [firstLaunch, setFirstLaunch] = useState(() => {
    const saved = localStorage.getItem('archivedTasks')
    return saved || ''
  })
  const [taskListOrder, setTaskListOrder] = useState([])
  const [taskEdit, setTaskEdit] = useState(false)
  const [taskInput, setTaskInput] = useState(false)
  const [view, setView] = useState('inbox')
  const [completedTasks, setCompletedTasks] = useState(null)
  const [formData, setFormData] = useState({
    title: '',
    committed: false,
    completed: false,
    description: '',
    archived: false,
    userRef: auth.currentUser.uid,
  })
  const [activeItemData, setActiveItemData] = useState({
    title: '',
    committed: false,
    completed: false,
    description: '',
    archived: false,
    userRef: auth.currentUser.uid,
  })

  // Check to make sure the user is logged in and pull their tasks on load
  useEffect(() => {
    fetchUserTasks()
    addUserIdForm()
    loadExternalEvents()
    // Get latest Google Calendar updates every x seconds
    setInterval(() => {
      incrementalSync()
    }, 10000)
    // Save order and task state every x minutes
    setInterval(() => {
      saveOrder()
    }, 60000)
  }, [])

  // Check to make sure the user is logged in and pull their tasks
  const fetchUserTasks = async () => {
    if (
      !localStorage.getItem('tasks') ||
      !localStorage.getItem('taskOrder') ||
      !localStorage.getItem('todayOrder') ||
      !localStorage.getItem('user')
    ) {
      const taskRef = collection(db, 'tasks')

      // Get tasks where userRef value = logged in user
      const q = query(taskRef, where('userRef', '==', auth.currentUser.uid))
      const querySnap = await getDocs(q)

      // Get user info which contains today and task order
      const userRef = doc(db, 'users', auth.currentUser.uid)
      const userSnap = await getDoc(userRef)

      // Initialize a task array to have them live somewhere once they've been pulled
      let tasks = []
      let taskOrder = []
      let todayOrder = []

      // Add the returned tasks from the query into the empty task array
      querySnap.forEach((doc) => {
        tasks.push({
          id: doc.id,
          data: doc.data(),
        })
      })

      if (tasks.length > 0) {
        // Add the returned order from the query into the empty task array
        taskOrder.push(userSnap.data().taskOrder)

        // Add the returned todayTaskOrder from the query into the empty todayOrder array
        todayOrder.push(userSnap.data().todayOrder)

        // Initial set to local storage
        localStorage.setItem('taskOrder', JSON.stringify(taskOrder))

        setTaskOrder(taskOrder)

        // Set user's info to localStorage
        localStorage.setItem('user', JSON.stringify(userSnap.data()))

        // Start the chain of getting tasks, sorting them into inbox and today, and putting them in the last known order
        fetchTodayTasks(tasks, todayOrder, taskOrder)
      } else {
        // Set localStorage so the app doesn't crash somewhere
        localStorage.setItem('tasks', JSON.stringify([]))
        localStorage.setItem('todayTasks', JSON.stringify([]))
        localStorage.setItem('todayOrder', JSON.stringify([[]]))
        localStorage.setItem('taskOrder', JSON.stringify([[]]))
        localStorage.setItem('archivedTasks', JSON.stringify([]))

        // Set state so the app doesn't crash
        setTasks([])
        setTodayTasks([])
        setTodayOrder([])
        setTaskOrder([])
        setArchivedTasks([])
        setLoading(false)
      }

      // }
    } else {
      // Get all important state items from local storage
      const tasks = JSON.parse(localStorage.getItem('tasks'))
      const taskOrder = JSON.parse(localStorage.getItem('taskOrder'))
      const todayOrder = JSON.parse(localStorage.getItem('todayOrder'))
      const todayTasks = JSON.parse(localStorage.getItem('todayTasks'))
      const archivedTasks = JSON.parse(localStorage.getItem('archivedTasks'))
      const localCalendarEvents = JSON.parse(
        localStorage.getItem('calendarEvents')
      )
      // Get user info which contains task and today order
      JSON.parse(localStorage.getItem('user'))

      // Set state
      setTaskOrder(taskOrder)
      setTodayOrder(todayOrder)
      setTasks(tasks)
      setTodayTasks(todayTasks)
      setArchivedTasks(archivedTasks)
      setLoading(false)
      setCalendarEvents(localCalendarEvents)
    }
  }

  // Sort tasks into the order the user left it in from the db
  const sortTasks = (tasks, taskOrder) => {
    const arrayMap = tasks.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    )

    let taskListOrder = []

    const result = taskOrder[0].map((id) => arrayMap[id])
    result.forEach((item) => {
      taskListOrder.push(item)
    })

    // Set State
    setTasks(taskListOrder)

    // Set to local storage
    localStorage.setItem('tasks', JSON.stringify(taskListOrder))

    setLoading(false)
  }

  // Move completed and committed tasks into their respective state
  const fetchTodayTasks = (tasks, todayOrder, taskOrder) => {
    let todayTasks = []
    let inboxTasks = []
    let archivedTasks = []

    tasks.forEach((task) => {
      ;(task.data.completed || task.data.committed) && !task.data.archived
        ? todayTasks.push({
            id: task.id,
            data: task.data,
          })
        : task.data.archived
        ? archivedTasks.push({
            id: task.id,
            data: task.data,
          })
        : inboxTasks.push({
            id: task.id,
            data: task.data,
          })
    })

    // Set state and local storage
    setArchivedTasks(archivedTasks)
    localStorage.setItem('archivedTasks', JSON.stringify(archivedTasks))

    sortTodayTasks(todayTasks, todayOrder, inboxTasks, taskOrder)
  }

  // Sort tasks into the order the user left it in from the db
  const sortTodayTasks = (todayTasks, todayOrder, inboxTasks, taskOrder) => {
    const arrayMap = todayTasks.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    )

    let taskListOrder = []

    if (todayOrder[0]) {
      const result = todayOrder[0].map((id) => arrayMap[id])
      result.forEach((item) => {
        taskListOrder.push(item)
      })
    }

    // Set state
    setTodayOrder(todayOrder)
    setTodayTasks(taskListOrder)

    // Set to local storage

    localStorage.setItem('todayTasks', JSON.stringify(taskListOrder))
    localStorage.setItem('todayOrder', JSON.stringify(todayOrder))

    // Sort the inbox tasks and then setLoading to false
    sortTasks(inboxTasks, taskOrder)
  }

  // Archive completed tasks and move incomplete committed tasks back to the inbox once it hits designated time (default: midnight)
  const resetToday = () => {
    let allTasks = []

    todayTasks.forEach((task) => {
      // If the task isn't completed, then set it to uncommit
      if (!task.data.completed) {
        task.data.committed = false
        task.data.markedForSync = true
        allTasks.push(task)
      }

      // If it is completed, then prepare it to be archived
      if (task.data.completed) {
        task.data.archived = true
        task.data.markedForSync = true
        archivedTasks.unshift(task)
      }
    })

    // Combine incomplete tasks and inbox tasks
    tasks.forEach((task) => {
      allTasks.push(task)
    })

    // Update task order
    let taskOrder = []
    allTasks.forEach((task) => {
      taskOrder.push(task.id)
    })

    // Set task state
    setTodayTasks([])
    setTasks(allTasks)
    setArchivedTasks(archivedTasks)

    // Prep data and set order state
    setTaskOrder(taskOrder)
    setTodayOrder([])

    const user = JSON.parse(localStorage.getItem('user'))
    const orderArray = {
      ...user,
      todayOrder: [],
      taskOrder: taskOrder,
    }

    // Set local storage
    localStorage.setItem('user', JSON.stringify(orderArray))
    localStorage.setItem('taskOrder', JSON.stringify([taskOrder]))
    localStorage.setItem('todayOrder', JSON.stringify([]))
    localStorage.setItem('tasks', JSON.stringify(allTasks))
    localStorage.setItem('archivedTasks', JSON.stringify(archivedTasks))
    localStorage.setItem('todayTasks', JSON.stringify([]))

    // Call the saveOrder function which saves all tasks state and taskOrder states
    saveOrder()
  }

  // Check to make sure the user is logged in and then set new task form data to their uid
  const addUserIdForm = async () => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({
            ...formData,
            userRef: user.uid,
          })
        }
      })
    }

    return () => {
      isMounted.current = false
    }
  }

  // Submit new task to database
  const addTask = async (formDataCopy) => {
    const tasks = JSON.parse(localStorage.getItem('tasks'))
    const taskOrder = JSON.parse(localStorage.getItem('taskOrder'))
    // Send to DB then update list on a successful promise
    try {
      await addDoc(collection(db, 'tasks'), formDataCopy).then((response) => {
        // Add new task to the task state array
        const newTask = {
          id: response.id,
          data: formDataCopy,
        }

        setTasks([newTask, ...tasks])
        localStorage.setItem('tasks', JSON.stringify([newTask, ...tasks]))
        setFormData({
          title: '',
          committed: false,
          completed: false,
          description: '',
          archived: false,
        })
        // Remove the add new task input field
        setTaskInput(false)

        // Add the new task to the beginning of the taskOrder state
        taskOrder[0].unshift(newTask.id)
        setTaskOrder(taskOrder)
        localStorage.setItem('taskOrder', JSON.stringify(taskOrder))

        // Each user should only have one tasks ordering array in their ordering collection.
        // Send the new taskOrder to the database. This allows the order to persist.
        const user = JSON.parse(localStorage.getItem('user'))
        const arrayDataCopy = {
          ...user,
          taskOrder: taskOrder[0],
        }

        // Set localStorage and send updated taskOrder info to DB
        localStorage.setItem('user', JSON.stringify(arrayDataCopy))
        updateTaskOrder(arrayDataCopy)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // Save a task to firestore
  const saveTask = async (updFormData, id) => {
    // Update listing in firestore
    const docRef = doc(db, 'tasks', id)
    await setDoc(docRef, updFormData)
  }

  const updateTask = async (updFormData, id) => {
    // Identify whether we should be updating Today or Inbox task state
    if (!updFormData.committed) {
      // Map through existing task state to find the id of the task you're going to change
      // pass in updated state data to task state
      const newTasks = tasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: updFormData,
          }
          return updatedItem
        }
        return item
      })

      setTasks(newTasks)
      localStorage.setItem('tasks', JSON.stringify(newTasks))
    }

    if (updFormData.committed) {
      // Map through existing task state to find the id of the task you're going to change
      // pass in updated state data to task state
      const newTasks = todayTasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: updFormData,
          }
          return updatedItem
        }
        return item
      })

      setTodayTasks(newTasks)
      localStorage.setItem('todayTasks', JSON.stringify(newTasks))

      // Update the state info for events being populated in the calendar
      const updatedCalEvents = calendarEvents.map((item) => {
        if (item.source.title) {
          const calEventTask = item.source.title.slice(9)
          if (calEventTask === id) {
            const updatedItem = {
              ...item,
              title: updFormData.title,
            }
            return updatedItem
          }
        }

        return item
      })

      setCalendarEvents(updatedCalEvents)
      localStorage.setItem('calendarEvents', JSON.stringify(updatedCalEvents))
    }
  }

  // Edit existing task and send to database
  // Note: I'm not sure if this is actually being used anywhere... Look into this at some point.
  const editTask = async (updFormData, id) => {
    const formDataCopy = {
      ...updFormData,
    }

    // Update listing in firestore
    const docRef = doc(db, 'tasks', id)
    await updateDoc(docRef, formDataCopy).then((response) => {
      // Map through the existing task state to find the id of the task you're changing.
      // then pass the updated state data to the task state
      const newTasks = tasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: formDataCopy,
          }
          return updatedItem
        }
        return item
      })
      setTasks(newTasks)
    })
  }

  // NOTE: There's an interesting bug here that exists because order does not save to the DB enough.
  // If you commit to a task from the inbox then delete that task from Today and then delete your local storage and refresh,
  // it will make the app crash because the taskOrder did not update to the DB but it DID delete the ticket from the DB,
  // so when it reloads, it tries to load in the deleted one per the taskOrder but the task does not exist.

  // Delete existing task from database and refresh task state
  const deleteTask = async (id, today) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const todayOrder = JSON.parse(localStorage.getItem('todayOrder'))
    const tasks = JSON.parse(localStorage.getItem('tasks'))
    const taskOrder = JSON.parse(localStorage.getItem('taskOrder'))
    // Delete it from the DB
    await deleteDoc(doc(db, 'tasks', id))
    // Once it's deleted, show the updated listings (make the deleted one from Firebase go away)
    if (today) {
      // Save task details to local storage and set state
      const updatedTasks = todayTasks.filter((item) => item.id !== id)
      setTodayTasks(updatedTasks)
      localStorage.setItem('todayTasks', JSON.stringify(updatedTasks))

      // Save order to local storage and set state
      const updatedOrder = todayOrder[0].filter((item) => item !== id)
      setTodayOrder(updatedOrder)
      localStorage.setItem('todayOrder', JSON.stringify([updatedOrder]))

      // Update order in DB
      const arrayData = {
        ...user,
        todayOrder: updatedOrder,
      }

      updateTaskOrder(arrayData)
      localStorage.setItem('user', JSON.stringify(arrayData))
    } else {
      // Save task details to local storage and set state
      const updatedTasks = tasks.filter((item) => item.id !== id)
      setTasks(updatedTasks)
      localStorage.setItem('tasks', JSON.stringify(updatedTasks))

      // Save order to local storage and set state
      const updatedOrder = taskOrder[0].filter((item) => item !== id)
      setTaskOrder(updatedOrder)
      localStorage.setItem('taskOrder', JSON.stringify([updatedOrder]))

      // Update order in DB
      const arrayData = {
        ...user,
        taskOrder: updatedOrder,
      }

      updateTaskOrder(arrayData)
      localStorage.setItem('user', JSON.stringify(arrayData))
    }
  }

  const archiveTask = (task) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const todayTasks = JSON.parse(localStorage.getItem('todayTasks'))
    const tasks = JSON.parse(localStorage.getItem('tasks'))
    const archivedTasks = JSON.parse(localStorage.getItem('archivedTasks'))

    // If the task is committed, loop through the todayTasks list and filter out the task
    if (task.committed) {
      // Change the data of the archived task
      const dataArray = todayTasks.map((item) => {
        if (task.id === item.id) {
          const archivedTaskData = {
            ...item.data,
            archived: true,
            markedForSync: true,
          }

          return {
            id: item.id,
            data: archivedTaskData,
          }
        }
        return item
      })

      // Remove the task from todayTasks
      const updatedTodayTasks = dataArray.filter((item) => item.id !== task.id)
      setTodayTasks(updatedTodayTasks)
      localStorage.setItem('todayTasks', JSON.stringify(updatedTodayTasks))

      // Set the order of todayOrder
      const updatedTodayOrder = []
      updatedTodayTasks.forEach((item) => {
        updatedTodayOrder.push(item.id)
      })
      setTodayOrder(updatedTodayOrder)
      localStorage.setItem('todayOrder', JSON.stringify([updatedTodayOrder]))

      // Prep user order data
      const userOrderData = {
        ...user,
        todayOrder: updatedTodayOrder,
      }

      localStorage.setItem('user', JSON.stringify(userOrderData))

      // Add the task to the archived tasks list
      const archivedTaskArray = dataArray.filter((item) => item.id === task.id)
      const archivedTask = archivedTaskArray[0]

      const updatedArchivedTasks = [archivedTask, ...archivedTasks]

      // Set archivedTasks state and localStorage
      setArchivedTasks(updatedArchivedTasks)
      localStorage.setItem(
        'archivedTasks',
        JSON.stringify(updatedArchivedTasks)
      )
    } else {
      // Change the data of the archived task
      const dataArray = tasks.map((item) => {
        if (task.id === item.id) {
          const archivedTaskData = {
            ...item.data,
            archived: true,
            markedForSync: true,
          }

          return {
            id: item.id,
            data: archivedTaskData,
          }
        }
        return item
      })

      // Remove the task from Tasks
      const updatedTasks = dataArray.filter((item) => item.id !== task.id)
      setTasks(updatedTasks)
      localStorage.setItem('tasks', JSON.stringify(updatedTasks))

      // Set the order of taskOrder
      const updatedTaskOrder = []
      updatedTasks.forEach((item) => {
        updatedTaskOrder.push(item.id)
      })
      setTodayOrder(updatedTaskOrder)
      localStorage.setItem('taskOrder', JSON.stringify([updatedTaskOrder]))

      // Prep user order data
      const userOrderData = {
        ...user,
        taskOrder: updatedTaskOrder,
      }

      localStorage.setItem('user', JSON.stringify(userOrderData))

      // Add the task to the archived tasks list
      const archivedTaskArray = dataArray.filter((item) => item.id === task.id)
      const archivedTask = archivedTaskArray[0]

      const updatedArchivedTasks = [archivedTask, ...archivedTasks]

      // Set archivedTasks state and localStorage
      setArchivedTasks(updatedArchivedTasks)
      localStorage.setItem(
        'archivedTasks',
        JSON.stringify(updatedArchivedTasks)
      )
    }
  }

  // Send task orders and task state in localStorage to firestore
  const saveOrder = () => {
    // Get data from local storage
    const taskOrder = JSON.parse(localStorage.getItem('taskOrder'))
    const todayOrder = JSON.parse(localStorage.getItem('todayOrder'))
    const inboxTasks = JSON.parse(localStorage.getItem('tasks'))
    const archivedTasks = JSON.parse(localStorage.getItem('archivedTasks'))
    const todayTasks = JSON.parse(localStorage.getItem('todayTasks'))
    const user = JSON.parse(localStorage.getItem('user'))

    // Save Inbox Tasks to firestore
    if (inboxTasks?.length > 0) {
      console.log(inboxTasks.length)
      const updatedInboxTasks = inboxTasks.map((task) => {
        if (inboxTasks?.length > 0 && task.data.markedForSync) {
          const updFormDataCopy = {
            ...task.data,
            markedForSync: false,
          }

          saveTask(updFormDataCopy, task.id)
          return {
            id: task.id,
            data: updFormDataCopy,
          }
        } else {
        }
        return task
      })

      setTasks(updatedInboxTasks)
      localStorage.setItem('tasks', JSON.stringify(updatedInboxTasks))
    }

    // Save Today Tasks to firestore
    if (todayTasks?.length > 0) {
      const updatedTodayTasks = todayTasks.map((task) => {
        if (task.data.markedForSync) {
          const updFormDataCopy = {
            ...task.data,
            markedForSync: false,
          }

          saveTask(updFormDataCopy, task.id)
          return {
            id: task.id,
            data: updFormDataCopy,
          }
        }
        return task
      })

      setTodayTasks(updatedTodayTasks)
      localStorage.setItem('todayTasks', JSON.stringify(updatedTodayTasks))
    }

    // Save Archived Tasks to firestore
    if (archivedTasks.length > 0) {
      const updatedArchivedTasks = archivedTasks.map((task) => {
        if (task.data.markedForSync) {
          const updFormDataCopy = {
            ...task.data,
            markedForSync: false,
          }

          saveTask(updFormDataCopy, task.id)
          return {
            id: task.id,
            data: updFormDataCopy,
          }
        }
        return task
      })

      setArchivedTasks(updatedArchivedTasks)
      localStorage.setItem(
        'archivedTasks',
        JSON.stringify(updatedArchivedTasks)
      )
    }

    // Update the task and today order arrays to firestore
    if (!user.todayOrder || user.todayOrder.length === 0) {
      const taskOrderArray = {
        ...user,
        taskOrder: taskOrder[0],
        todayOrder: [],
      }

      updateTaskOrder(taskOrderArray)
      localStorage.setItem('user', JSON.stringify(taskOrderArray))
    } else {
      const taskOrderArray = {
        ...user,
        taskOrder: taskOrder[0],
        todayOrder: todayOrder[0],
      }

      updateTaskOrder(taskOrderArray)
      localStorage.setItem('user', JSON.stringify(taskOrderArray))
    }
  }

  // Update taskOrder to firestore
  const updateTaskOrder = async (arrayDataCopy) => {
    const docRef = doc(db, 'users', auth.currentUser.uid)
    await setDoc(docRef, arrayDataCopy)
  }

  // Commit a task for today
  const commitTask = (event) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const committedData = {
      ...event,
      completed: event.completed === 1 ? true : false,
      archived: event.archived === 1 ? true : false,
      committed: true,
      markedForSync: true,
    }

    // Note: I should probably change this to if (event.committed) as I think it might be more reliable
    if (event.column === 'today') {
      const newTasks = todayTasks.map((item) => {
        if (item.id === event.id) {
          const updatedItem = {
            ...item,
            data: committedData,
          }
          return updatedItem
        }
        return item
      })

      const todayOrder = []
      newTasks.forEach((item) => {
        todayOrder.push(item.id)
      })

      // Get user data from localStorage and update
      const arrayData = {
        ...user,
        todayOrder: todayOrder,
      }

      // Set state & local storage
      setTodayTasks(newTasks)
      setTodayOrder(todayOrder)
      localStorage.setItem('todayTasks', JSON.stringify(newTasks))
      localStorage.setItem('todayOrder', JSON.stringify([todayOrder]))
      localStorage.setItem('user', JSON.stringify(arrayData))
    } else {
      tasks.map((item) => {
        if (item.id === event.id) {
          const updatedItem = {
            ...item,
            data: committedData,
          }

          // Remove the list from the inbox column and put it to the top of the today column
          const updatedTasks = tasks.filter((task) => task.id !== event.id)
          const updatedTodayTasks = [...todayTasks, updatedItem]

          // Set Tasks to state
          setTasks(updatedTasks)
          setTodayTasks(updatedTodayTasks)

          const todayOrder = []
          updatedTodayTasks.forEach((item) => {
            todayOrder.push(item.id)
          })
          setTodayOrder(todayOrder)

          // Set new task order for Inbox
          const updatedTaskOrder = []
          updatedTasks.forEach((task) => {
            updatedTaskOrder.push(task.id)
          })

          // Get user data from localStorage and update
          const arrayData = {
            ...user,
            taskOrder: updatedTaskOrder,
            todayOrder: todayOrder,
          }

          setTaskOrder(updatedTaskOrder)

          // Save to local storage
          localStorage.setItem('taskOrder', JSON.stringify([updatedTaskOrder]))
          localStorage.setItem('todayOrder', JSON.stringify([todayOrder]))
          localStorage.setItem('tasks', JSON.stringify(updatedTasks))
          localStorage.setItem('todayTasks', JSON.stringify(updatedTodayTasks))
          localStorage.setItem('user', JSON.stringify(arrayData))

          toast.success(`Task moved to Focus view`)
          return updatedItem
        }
        return item
      })
    }
  }

  // Uncommit a task (move it back to the inbox and remove it from the calendar)
  const uncommitTask = (id) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const tasks = JSON.parse(localStorage.getItem('tasks'))
    const todayTasks = JSON.parse(localStorage.getItem('todayTasks'))

    // Find the proper task in todayTasks from the id passed in
    // Note: activeItemData doesn't have the proper task info,
    // which is why we have to loop todayTasks to find the proper info
    const filterTask = todayTasks.filter((item) => item.id === id)
    const sourceTask = filterTask[0]

    // Prep new data state for the task
    const uncommittedData = {
      ...sourceTask.data,
      committed: false,
      completed: false,
      markedForSync: true,
      archived: false,
    }

    // Remove the task from todayTasks and todayOrder list
    const removedTodayTasks = todayTasks.filter(
      (item) => item.id !== sourceTask.id
    )

    const removedTodayOrder = []
    removedTodayTasks.forEach((item) => {
      removedTodayOrder.push(item.id)
    })

    // Update todayTask state to not have the task
    setTodayTasks(removedTodayTasks)
    localStorage.setItem('todayTasks', JSON.stringify(removedTodayTasks))

    // Update todayOrder state to not have the task
    setTodayOrder(removedTodayOrder)
    localStorage.setItem('todayOrder', JSON.stringify([removedTodayOrder]))

    const updSourceTask = {
      ...sourceTask,
      data: uncommittedData,
    }

    const updatedTasks = [updSourceTask, ...tasks]

    // Set the state
    setTasks(updatedTasks)
    localStorage.setItem('tasks', JSON.stringify(updatedTasks))

    // Add the task to tasks and taskOrder list
    const updTaskOrder = []
    updatedTasks.forEach((item) => {
      updTaskOrder.push(item.id)
    })

    // Prep user taskOrder and todayOrder data to update user localStorage state
    const userArrayData = {
      ...user,
      todayOrder: removedTodayOrder,
      taskOrder: updTaskOrder,
    }

    setTaskOrder(updTaskOrder)
    localStorage.setItem('taskOrder', JSON.stringify([updTaskOrder]))
    localStorage.setItem('user', JSON.stringify(userArrayData))

    toast.success(`Task moved back to Inbox`)
  }

  // Mark a task as complete
  const completeTask = (task, id) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const completedData = {
      ...task,
      completed: true,
      committed: true,
      markedForSync: true,
    }

    if (task.committed) {
      const newTasks = todayTasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: completedData,
          }
          return updatedItem
        }
        return item
      })

      // Set state & local storage
      setTodayTasks(newTasks)
      localStorage.setItem('todayTasks', JSON.stringify(newTasks))
    } else {
      tasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: completedData,
          }

          // Remove the list from the inbox column and put it to the top of the today column
          const updatedTasks = tasks.filter((task) => task.id !== id)
          const updatedTodayTasks = [...todayTasks, updatedItem]

          // Set Tasks to state
          setTasks(updatedTasks)
          setTodayTasks(updatedTodayTasks)

          // Set new task order state for Today column
          todayOrder[0].push(id)
          setTodayOrder(todayOrder)

          // Set new task order for Inbox
          const updatedTaskOrder = []
          updatedTasks.forEach((task) => {
            updatedTaskOrder.push(task.id)
          })

          const userArrayData = {
            ...user,
            taskOrder: updatedTaskOrder,
            todayOrder: todayOrder[0],
          }

          setTaskOrder(updatedTaskOrder)

          // Save to local storage
          localStorage.setItem('taskOrder', JSON.stringify([updatedTaskOrder]))
          localStorage.setItem('todayOrder', JSON.stringify(todayOrder))
          localStorage.setItem('tasks', JSON.stringify(updatedTasks))
          localStorage.setItem('todayTasks', JSON.stringify(updatedTodayTasks))
          localStorage.setItem('user', JSON.stringify(userArrayData))

          return updatedItem
        }
        return item
      })
    }
  }

  // Unmark a task as complete
  const uncompleteTask = (task, id) => {
    const uncompleteData = {
      ...task,
      completed: false,
      committed: true,
      markedForSync: true,
    }

    if (task.committed) {
      const newTodayTasks = todayTasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: uncompleteData,
          }
          return updatedItem
        }
        return item
      })

      // Set state & local storage
      setTodayTasks(newTodayTasks)
      localStorage.setItem('todayTasks', JSON.stringify(newTodayTasks))
    } else {
      // This part is technically not possible, but I'm going to leave it here anyways.
      const newTasks = tasks.map((item) => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            data: uncompleteData,
          }
          return updatedItem
        }
        return item
      })

      setTasks(newTasks)
    }
  }

  // Load external events (tasks) to be draggable for FullCalendar
  const loadExternalEvents = () => {
    let draggableEl = document.getElementById('taskListMain')
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: '.taskItemContainer',
        eventData: function (eventEl) {
          let id = eventEl.dataset.id
          let title = eventEl.getAttribute('title')
          let description = eventEl.dataset.description
          let completed = eventEl.dataset.completed
          let column = eventEl.dataset.column
          let userRef = eventEl.dataset.userref

          return {
            id: id,
            title: title,
            description: description,
            completed: completed,
            column: column,
            userRef: userRef,
            create: false,
          }
        },
      })
    }
  }

  const focusCheck = (functionName) => {
    if (document.hasFocus()) {
      if (functionName === 'newDay') {
        newDay()
      }

      if (functionName === 'incSync') {
        incrementalSync()
      }
    }
  }

  const newDay = () => {
    const firstTime = new Date().getDate()

    // If there's no firstLaunch in localStorage, set it to today.
    if (localStorage.getItem('firstLaunch') == null) {
      localStorage.setItem('firstLaunch', firstTime)
    } else {
      const storedTime = localStorage.getItem('firstLaunch')

      // Get a new date, zero it as above and see if its the same time
      // If not, it must be a different day
      const secondTime = new Date().getDate()
      if (secondTime > storedTime) {
        // This is the user's first time in the day, so we will call resetToday()
        resetToday()
        localStorage.setItem('firstLaunch', secondTime)
      }
    }
  }

  return (
    <TaskContext.Provider
      value={{
        tasks,
        formData,
        loading,
        openModal,
        taskEdit,
        taskInput,
        taskOrder,
        todayOrder,
        taskListOrder,
        view,
        todayTasks,
        archivedTasks,
        completedTasks,
        activeItemData,
        auth,
        confirmReset,
        setTasks,
        setTaskEdit,
        setTaskInput,
        setTaskOrder,
        setTodayOrder,
        setTaskListOrder,
        setTodayTasks,
        setCompletedTasks,
        setArchivedTasks,
        setFormData,
        setView,
        setOpenModal,
        setActiveItemData,
        addTask,
        updateTask,
        editTask,
        deleteTask,
        archiveTask,
        commitTask,
        uncommitTask,
        completeTask,
        uncompleteTask,
        updateTaskOrder,
        saveOrder,
        resetToday,
        newDay,
        focusCheck,
        setConfirmReset,
      }}
    >
      {children}
    </TaskContext.Provider>
  )
}

export default TaskContext
