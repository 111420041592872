// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyANYOrSwRWPyt3Ap0ARcdWvPyG6AEiumIg',
  authDomain: 'poetical-12953.firebaseapp.com',
  projectId: 'poetical-12953',
  storageBucket: 'poetical-12953.appspot.com',
  messagingSenderId: '333772730770',
  appId: '1:333772730770:web:c0c032738abc1a6be1514b',
  measurementId: 'G-C3ZQV2W4LM',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export const db = getFirestore()
