import poeticLogo from '../assets/svg/poeticLogo.svg'
import { toast } from 'react-toastify'

function Navbar() {
  const complimentGenerator = () => {
    const ranNum = Math.floor(Math.random() * 6)
    const quotes = [
      {
        id: 0,
        message: `Nice!`,
      },
      {
        id: 1,
        message: 'You know, I bet you look great today.',
      },
      {
        id: 2,
        message: `I feel like you're going to really crush it today.`,
      },
      {
        id: 3,
        message: `Hey... I like your style.`,
      },
      {
        id: 4,
        message: `Hey... You got this.`,
      },
      {
        id: 5,
        message: `You're going to achieve a ton today. I know it.`,
      },
    ]

    quotes.map((quote) => {
      console.log(ranNum)
      if (quote.id === ranNum) {
        toast.success(quote.message)
      }
      return quote
    })
  }

  const onClick = () => {
    complimentGenerator()
  }

  return (
    <div className='navbar bg-base-100'>
      <div className='flex-1'>
        <button className='btn btn-square btn-ghost hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            className='inline-block w-5 h-5 stroke-current'
          ></svg>
        </button>
      </div>
      <div className='flex-1'>
        <img
          src={poeticLogo}
          style={{ width: 40, height: 40 }}
          alt='logo'
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default Navbar
